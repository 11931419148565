<template lang="pug">
  .tab-main-container
    .headline 記録詳細
    div.p-3(v-if="!this.recordRoute") {{ this.message }}
    div(v-if="this.recordRoute")
      .row.justify-content-md-center#rock-img-list.mt-3
        .col-6.col-md-4
          .rock-img
            img(:src="materialURL + imgPath")
            .rocky-name {{ this.rockyName }}
        .col-6.col-md-4
          .text-left
            div.rockyTaitle.pl-2 この岩場の記録
            div.countNumber.pt-2.pb-2.pl-1 登攀した岩
              span.Number.pl-5 {{ rockNumber }}
            div.countNumber.pt-2.pb-2.pl-1 登攀したルート
              span.Number.pl-4 {{ routeNumber}}
      .text-center.mb-3
        button.btn.mr-5(@click="callRockList" :class="{ rock: dataList}") 岩
        button.btn(@click="callRouteList" :class="{ rock: !dataList}") ルート
      //-岩リスト
      table.table.mb-3(v-if="dataList")
        tr.tableLabel.small
          td.align-middle 岩名
          td.align-middle 登攀記録日
        tr.small(v-for="item in rockList")
          td.align-middle {{ item.rockName }}
          td.align-middle {{ item.climbDate.slice(0, 10).replace(/-/g, '/') }}
        //-ルートリスト
      table.table.mb-4(v-if="!dataList")
        template(v-for="data in recordRoute")
          tr.small(v-if="data.rockName")
            td.tableLabel.align-middle(colspan=3) {{ data.rockName}}
          tr.routeListBody.small(v-else @click="callrouteDetail(data.routeId,data.rockId)")
            td.align-middle {{ data.routeName }}
            td.align-middle {{ data.gradeName }}
            td.align-middle.d-sm-none.d-block
              .climbKind.badge.p-2(:class="{'badge-primary': data.climbKind!=4, 'badge-secondary': data.climbKind==4}") {{ ClimbKind[data.climbKind].slice(0, 1) }}
            td.align-middle.d-sm-block.d-none.text-right
              .climbKind.badge.p-2(style="width:50%" :class="{'badge-primary': data.climbKind!=4, 'badge-secondary': data.climbKind==4}") {{ ClimbKind[data.climbKind] }}
</template>

<script>
import axios from 'axios'

const climbKind = {
  1: 'オンサイト',
  2: 'フラッシュ',
  3: '完登',
  4: 'チャレンジ',
}

export default {
  data() {
    return {
      materialURL: process.env.VUE_APP_MATERIAL_URL,
      rockyId: this.$route.query.rockyId,
      rockyName: '',
      rockList: [],
      rockNumber:'',
      routeNumber: '',
      dataList: true,
      imgPath: '',
      allRocky: null,
      recordRoute: null,
      ClimbKind: climbKind,
      message: '',
    }
  },	
  methods: {
    //ルートリスト表示
    callRouteList() {
      this.dataList = false
    },
    //ルート詳細遷移
    callrouteDetail(routeId,rockId) {
      this.$router.push({ name: 'myPage_:userId_routeDetail', params: { userId: this.$route.params.userId }, query: { rockyId: this.rockyId, routeId: routeId, rockId: rockId} })
    },
    //岩リスト表示
    callRockList() {
      this.dataList = true
    }
  },
  async created() {
    // 岩場画像取得
    let imgJsonUrl = process.env.VUE_APP_MATERIAL_URL + '/allRockyList.json'
    await axios
      .get(imgJsonUrl)
      .then(response => {
        this.allRocky = response.data
        for(let i=0; i<this.allRocky.length;i++) {
          if(this.allRocky[i].rockyId == this.rockyId) {
            this.imgPath = this.allRocky[i].imgPath
            this.rockyName = this.allRocky[i].name
            break
          }
        }
      })
      .catch(err => {
        alert('システムエラーが発生しました')
      })

    //岩リスト記録データ取得処理
    const jsonUrl = process.env.VUE_APP_API_GATEWAY + '/api-record'
    await axios.get(jsonUrl,{
      params: {
        'rockyId': this.rockyId,
        'targetUserId': this.$route.params.userId,
        'type': 'rockList',
        
      }, 
      headers: {
        'Authorization': this.$user.isLogin ? this.$user.idToken : 'no-login'
      }
    })
      .then(response => {
        this.rockList = response.data
      })
      .catch(err => {
        this.message = '情報の取得に失敗しました'
      })
      
    const jsonUrl2 = process.env.VUE_APP_API_GATEWAY + '/api-record'
    await axios.get(jsonUrl2,{
      params: {
        'rockyId': this.rockyId,
        'targetUserId': this.$route.params.userId,
        'type': 'routeList',
      },         
      headers: {
        'Authorization': this.$user.isLogin ? this.$user.idToken : 'no-login'
      }
    })
      .then(response => {
        if(response.data == null) {
          this.message = '非公開'
        }else {
          let res = response.data
          let items = []
          for (let idx = 0; idx < res.length; idx++) {
            let rock = res[idx]
            items.push(rock)
            for (let routeIdx = 0; routeIdx < rock.routeList.length; routeIdx++) {
              items.push(rock.routeList[routeIdx])
            }
          }
          this.recordRoute = items
          
          this.rockNumber = res.length
          this.routeNumber = items.length - this.rockNumber
        }
      })
      .catch(err => {
        this.message = '情報の取得に失敗しました'
      })
  },
}
</script>

<style lang="stylus" scoped>
.countNumber
  border 2px solid #ccc
  font-size 0.8rem
  .Number
    font-size 1.1rem
    font-weight 900
.climbKind
  font-size 0.8rem
.rockyTaitle
  font-size 80%
  color #FFF
  background-color #333333
.rock
  color #FFF
  background-color #004170
.routeListBody
  cursor pointer
button
	width 40%
.tableLabel
  background-color #004170
  color #FFF
#rock-img-list
  margin-left 5px
  margin-right 5px
  padding 20px 5px 
  .col-6.col-md-4 
    padding-left 0px
    padding-right 0px
    position relative
  .rock-img,.rocky-name
    margin-bottom 5px
  img
    width 98%
    height auto
    border-radius 0%
  .rocky-name
    position absolute
    color white
    bottom 0
    width 98%
    height 20%
    padding-left 0.5rem
    font-size 0.8rem
    background-color rgba(0, 0, 0, 0.5)
</style>